import imageUpload from '../../assets/icons/plus.png';
import '../../assets/styles/pages.scss'
import { useLocation, useMatch, useNavigate, useParams } from 'react-router-dom'
import { Fragment, useContext, useEffect, useState } from 'react';
import henceforthApi from '../../utils/henceforthApi';
import { GlobalContext, handleError } from '../../context/Provider';
import Spinner from '../../components/common/spinner';
import { documentId, formValidate, NumberValidation } from '../../utils/validations';
import Errormessage from '../../components/common/errormessage';
import BreadCrumb from '../../components/common/BreadCrumb';
import { editBrandListing, editProductData, subLists } from './productInterface';
import Input, { SaveCancelButton } from '../../components/common/Inputs';
import Select from '../../components/common/Select';
import commonArray from '../../components/common/commonArray';
import Textarea from '../../components/common/Textarea';
import Inputs from '../../components/common/Inputs';
import { toast } from 'react-toastify';
import Debounce from '../../components/common/Debounce';


const EditProduct = () => {
    const { authState, authDispatch, language_data, loading, setLoading, toastMessage } = useContext(GlobalContext);
    const match = useMatch('/product/:id/edit')
    const navigate = useNavigate()
    const { id } = useParams()
    let breadCrumbPath = [
        { name: ` ${language_data?.[authState.lang]?.common_home}`, url: `/`, active: '' },
        { name: `${language_data?.[authState.lang]?.common_product} ${language_data?.[authState.lang]?.common_list}`, url: `/products/1`, active: '' },
        { name: `${language_data?.[authState.lang]?.common_product} ${language_data?.[authState.lang]?.common_deatil}`, url: `/product/${match?.params.id}`, active: '' },
        { name: `${language_data?.[authState.lang]?.common_edit} ${language_data?.[authState.lang]?.common_product}`, url: ``, active: 'not-allowed' }
    ]

    const CampaignCreateType: any = [
        { name: 'Everyone', _id: 'EVERYONE' },
        { name: 'No one', _id: 'NO_ONE' },
        { name: 'Selected User', _id: 'SELECTED_USER' }
    ]
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const [nameErr, setNameErr] = useState<string>("")
    const [brandErr, setBrandErr] = useState<string>("")
    const [priceErr, setPriceErr] = useState<string>("")
    const [taxErr, setTaxErr] = useState<string>("")
    const [categoryErr, setCategoryErr] = useState<string>("")
    const [quantityErr, setQuantityErr] = useState<string>("")
    const [wholesaleQuantityErr, setWholeSaleQuantityErr] = useState<string>("")
    const [wholesalePriceErr, setWholesalePriceErr] = useState<string>("")
    const [campaignQuantityErr, setCampaignQuantityErr] = useState<string>("")
    const [campaignCreateTypeErr, setCampaignCreateTypeErr] = useState<string>("")
    const [pageLoading, setPageLoading] = useState<boolean>(false)
    const [descriptionErr, setDescriptionErr] = useState<string>("")
    const [subCategoryErr, setSubcategoryErr] = useState<string>("")
    const [selectedFileErr, setSelectedFileErr] = useState<string>('')
    const [subCategory, setSubCategory] = useState<Array<subLists>>([])
    const [subSubCategory, setSubSubCategory] = useState<Array<subLists>>([])
    const [selectedFile, setSelectedFile] = useState<Array<Blob | MediaSource>>([])
    const [brandDetails, setBrandDetails] = useState({
        brand: [],
        category: [],
    } as editBrandListing)
    const [editProduct, setEditProduct] = useState({
        name: '',
        description: '',
        brand_id: '',
        category_id: '',
        subcategory_id: '',
        sub_subcategory_id: '',
        images: [],
        tax_percentage: '',
        quantity: '',
        price: '',
        wholesale_quntity: '',
        wholesale_price: '',
        campaign_quantity: '',
        discount_percantage: '',
        campaign_create_type: '',
        sold: false,
        is_blocked: false,
        is_deleted: false,
        language: '',
        brandObject: { _id: '', name: '' }

    } as editProductData);
    const [searchData, setSearchedData] = useState([] as Array<{
        email: string,
        name: string,
        profile_pic: null | string,
        _id: string,
    }>)
    const [selected, setSelected] = useState([] as Array<{
        email: string,
        name: string,
        profile_pic: null | string,
        _id: string,
        index: number
    }>)
    const brandListing = async () => {
        try {
            let apiBrandRes = (await henceforthApi.categorylisting.Brandlisting('')).data.data;
            let apiCategoryRes = (await henceforthApi.categorylisting.categorylisting()).data.data;
            setBrandDetails({
                ...brandDetails,
                brand: apiBrandRes,
                category: apiCategoryRes,
            });
        } catch (err) {
            handleError(err, '', authDispatch);
        }
    };
    useEffect(() => {
        brandListing();
    }, []);
    const subListing = async () => {
        try {
            let apiSubCategoryRes = (await henceforthApi.categorylisting.subCategorylisting(editProduct.category_id
            )).data.data;
            setSubCategory(apiSubCategoryRes)
        } catch (err) {
            handleError(err, '', authDispatch);

        }
    }
    const sub_subListing = async () => {
        let apiSubSubCategoryRes = (await henceforthApi.categorylisting.subSubCategorylisting(editProduct.subcategory_id)).data.data;

        setSubSubCategory(apiSubSubCategoryRes)
    }
    useEffect(() => {
        if ((editProduct.category_id ?? '') !== "") {
            subListing()
        }
    }, [editProduct.category_id])
    useEffect(() => {
        if ((editProduct.subcategory_id ?? '') !== "") {
            sub_subListing()
        }
    }, [editProduct.subcategory_id])
    const handleFile = async (e: any) => {
        let files = e.target.files as Array<Blob | MediaSource>
        setSelectedFile([...selectedFile, ...files])
        e.target.value = ""
    };
    const clearInputErr = (name: string) => {
        if (name === 'name') setNameErr('')
        if (name === 'description') setDescriptionErr('')
        if (name === 'brand_id') setBrandErr('')
        if (name === 'category_id') setCategoryErr('')
        // if (name === 'category_id'&& categoryErr) setEditProduct((editProduct) => {return {...editProduct,subcategory_id: '',}});
        if (name === 'subcategory_id') setSubcategoryErr('')
        // if (name === 'subcategory_id' && subCategoryErr) setEditProduct((editProduct) => {return {...editProduct,subcategory_id: '',}});
        // if (name === 'subcategory_id') setCategoryErr('')
        if (name === 'tax_percentage') setTaxErr('')
        if (name === 'quantity') setQuantityErr('')
        if (name === 'price') setPriceErr('')
        if (name === 'wholesale_quntity') setWholeSaleQuantityErr('')
        if (name === 'wholesale_price') setWholesalePriceErr('')
        if (name === 'campaign_quantity') setCampaignQuantityErr('')
        if (name === 'campaign_create_type') setCampaignCreateTypeErr('')
    }
    const handleChange = (e: any) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name === "campaign_create_type") {
            navigate(`/product/${id}/edit?campaign_type=${value}`);
        }
        clearInputErr(name)
        let res = formValidate(name, value)
        if (res === false) return
        if (name === 'category_id' && !value) setEditProduct((editProduct) => { return { ...editProduct, subcategory_id: '', sub_subcategory_id: '' } });
        if (name === 'subcategory_id' && !value) setEditProduct((editProduct) => { return { ...editProduct, sub_subcategory_id: '', } });
        setEditProduct((editProduct) => {
            return {
                ...editProduct,
                [name]: value,
            }
        });
    };

    if(query.get('campaign_type') === "NO_ONE"){
        editProduct.campaign_quantity = ""
    }
    const uploadImages = async () => {
        const images = [] as Array<string>
        await Promise.all(selectedFile.map(async (res) => {
            try {
                const apiRes = await henceforthApi.Common.do_spaces_file_upload(`file`, res)
                const data = apiRes.data
                images.push(data.file_name)
            } catch (err) {
                handleError(err, 'active', authDispatch);
            }
        }
        ))
        return images
    }
    const handleSubmit = async (e: any) => {
        debugger
        e.preventDefault();
        if (!selectedFile.length && !editProduct.name && !editProduct.description && !editProduct.tax_percentage && !editProduct.brand_id && !editProduct.category_id && !editProduct.subcategory_id && !editProduct.quantity && !editProduct.price) {
            setSelectedFileErr(`${language_data?.[authState.lang]?.common_image}${language_data?.[authState.lang]?.common_is}${language_data?.[authState.lang]?.common_required}`)
            setNameErr(`${language_data?.[authState.lang]?.common_name}${language_data?.[authState.lang]?.common_is}${language_data?.[authState.lang]?.common_required}`)
            setDescriptionErr(`${language_data?.[authState.lang]?.common_description}${language_data?.[authState.lang]?.common_is}${language_data?.[authState.lang]?.common_required}`)
            setBrandErr(`${language_data?.[authState.lang]?.common_brand}${language_data?.[authState.lang]?.common_is}${language_data?.[authState.lang]?.common_required}`)
            setTaxErr(`${language_data?.[authState.lang]?.common_tax}${language_data?.[authState.lang]?.common_is}${language_data?.[authState.lang]?.common_required}`)
            setCategoryErr(`${language_data?.[authState.lang]?.common_category}${language_data?.[authState.lang]?.common_is}${language_data?.[authState.lang]?.common_required}`)
            setSubcategoryErr(`${language_data?.[authState.lang]?.common_sub_category}${language_data?.[authState.lang]?.common_is}${language_data?.[authState.lang]?.common_required}`)
            setQuantityErr(`${language_data?.[authState.lang]?.common_quantity}${language_data?.[authState.lang]?.common_is}${language_data?.[authState.lang]?.common_required}`)
            setPriceErr(`${language_data?.[authState.lang]?.common_price}${language_data?.[authState.lang]?.common_is}${language_data?.[authState.lang]?.common_required}`)
            setWholeSaleQuantityErr(`${language_data?.[authState.lang]?.common_wholesale} ${language_data?.[authState.lang]?.common_quantity} ${language_data?.[authState.lang]?.common_is} ${language_data?.[authState.lang]?.common_required}`)
            setWholesalePriceErr(`${language_data?.[authState.lang]?.common_wholesale} ${language_data?.[authState.lang]?.common_price} ${language_data?.[authState.lang]?.common_is} ${language_data?.[authState.lang]?.common_required}`)
            setCampaignQuantityErr(`${language_data?.[authState.lang]?.common_campaign} ${language_data?.[authState.lang]?.common_quantity} ${language_data?.[authState.lang]?.common_is} ${language_data?.[authState.lang]?.common_required}`)
            setCampaignCreateTypeErr(`${language_data?.[authState.lang]?.common_campaign} ${language_data?.[authState.lang]?.common_create} ${language_data?.[authState.lang]?.common_type} ${language_data?.[authState.lang]?.common_is} ${language_data?.[authState.lang]?.common_required}`)
            return
        }
        if (selectedFile.length === 0 && editProduct.images.length === 0) { setSelectedFileErr(`${language_data?.[authState.lang]?.common_please}${language_data?.[authState.lang]?.common_select}${language_data?.[authState.lang]?.common_image}`); documentId('images'); return }
        if (!editProduct.name.trim()) { documentId('productName'); setNameErr(`${language_data?.[authState.lang]?.common_please}${language_data?.[authState.lang]?.common_provide}${language_data?.[authState.lang]?.common_name}`); return }
        if (!editProduct.description.trim()) { documentId('description'); setDescriptionErr(`${language_data?.[authState.lang]?.common_please}${language_data?.[authState.lang]?.common_provide}${language_data?.[authState.lang]?.common_description}`); return }
        if (!editProduct.price) { documentId('price'); setPriceErr(`Please add price`); return }
        if (!editProduct.tax_percentage) { documentId('tax_percentage'); setTaxErr(`${language_data?.[authState.lang]?.common_please}${language_data?.[authState.lang]?.common_add}${language_data?.[authState.lang]?.common_tax}`); return }
        if (!editProduct.quantity) { documentId('quantity'); setQuantityErr(`${language_data?.[authState.lang]?.common_please}${language_data?.[authState.lang]?.common_add}${language_data?.[authState.lang]?.common_quantity}`); return }
        if (!editProduct.wholesale_quntity) { documentId('wholesale_quntity'); setWholeSaleQuantityErr(`${language_data?.[authState.lang]?.common_please} ${language_data?.[authState.lang]?.common_add} ${language_data?.[authState.lang]?.common_wholesale} ${language_data?.[authState.lang]?.common_quantity}`); return }
        // if (!Number(editProduct.wholesale_quntity)) { documentId('wholesale_quntity'); setWholeSaleQuantityErr(`${language_data?.[authState.lang]?.common_only} ${language_data?.[authState.lang]?.common_use} ${language_data?.[authState.lang]?.common_number} ${language_data?.[authState.lang]?.common_in} ${language_data?.[authState.lang]?.common_quantity}`); return }
        if (!editProduct.wholesale_price) { documentId('wholesale_price'); setWholesalePriceErr(`${language_data?.[authState.lang]?.common_please} ${language_data?.[authState.lang]?.common_add} ${language_data?.[authState.lang]?.common_wholesale} ${language_data?.[authState.lang]?.common_price}`); return }
        // if (!Number(editProduct.wholesale_price)) { documentId('wholesale_price'); setWholesalePriceErr(`${language_data?.[authState.lang]?.common_only} ${language_data?.[authState.lang]?.common_use} ${language_data?.[authState.lang]?.common_number} ${language_data?.[authState.lang]?.common_in} ${language_data?.[authState.lang]?.common_quantity}`); return }
        if (query.get('campaign_type') !== "NO_ONE" && !(editProduct.campaign_quantity)) { documentId('campaign_quantity'); setCampaignQuantityErr(`Please add campaign quantity`); return }
        // if (!Number(editProduct.campaign_quantity)) { documentId('campaign_quantity'); setCampaignQuantityErr(`${language_data?.[authState.lang]?.common_only} ${language_data?.[authState.lang]?.common_use} ${language_data?.[authState.lang]?.common_number} ${language_data?.[authState.lang]?.common_in} ${language_data?.[authState.lang]?.common_campaign} ${language_data?.[authState.lang]?.common_quantity}`); return }
        // if (!Number(editProduct.quantity)) { documentId('quantity'); setQuantityErr(`${language_data?.[authState.lang]?.common_only}${language_data?.[authState.lang]?.common_use}${language_data?.[authState.lang]?.common_number}${language_data?.[authState.lang]?.common_in}${language_data?.[authState.lang]?.common_quantity}`); return }
        if (!editProduct.brand_id.trim()) { documentId('brand_id'); setBrandErr(`${language_data?.[authState.lang]?.common_please}${language_data?.[authState.lang]?.common_select}${language_data?.[authState.lang]?.common_brand}`); return }
        if (!editProduct.category_id.trim()) { documentId('category_id'); setCategoryErr(`${language_data?.[authState.lang]?.common_please}${language_data?.[authState.lang]?.common_select}${language_data?.[authState.lang]?.common_category}`); return }
        if (!editProduct.subcategory_id.trim()) { documentId('subcategory_id'); setSubcategoryErr(`${language_data?.[authState.lang]?.common_please}${language_data?.[authState.lang]?.common_select}${language_data?.[authState.lang]?.common_sub_category}`); return }
        setLoading(true)
        const images = await uploadImages()
        // product_type:'WAERABLE_PRODUCT',
        // product_type:'ELECTRONICS',
        const items = {
            _id: match?.params.id,
            name: editProduct.name,
            description: editProduct.description,
            brand_id: editProduct.brand_id,
            category_id: editProduct.category_id,
            subcategory_id: editProduct.subcategory_id,
            sub_subcategory_id: editProduct.sub_subcategory_id,
            images: [...images, ...editProduct.images],
            wholesale_quntity: editProduct.wholesale_quntity,
            wholesale_price: editProduct?.wholesale_price,
            campaign_quantity: editProduct?.campaign_quantity,
            tax_percentage: Number(editProduct.tax_percentage),
            quantity: Number(editProduct.quantity),
            price: Number(editProduct.price),
            campaign_create_type: editProduct?.campaign_create_type,
            discount_percantage: Number(editProduct.discount_percantage),
            campaign_create_selected_members: selected?.map((res: any) => res?._id),
            language: "ENGLISH"
        }
        try {
            let res = await henceforthApi.Product.editProduct(items)
            // toastMessage(res.message)
            toastMessage("Product Edited Successfully")

            setEditProduct({
                name: "",
                description: "",
                brand_id: "",
                category_id: "",
                subcategory_id: "",
                sub_subcategory_id: '',
                images: [],
                quantity: '',
                price: "",
                discount_percantage: '',
                tax_percentage: '',
                sold: false,
                campaign_create_type: '',
                is_blocked: false,
                wholesale_quntity: '',
                wholesale_price: '',
                campaign_quantity: '',
                is_deleted: false,
                language: "ENGLISH",
                brandObject: { _id: '', name: '' }
            });
            navigate(`/product/${res.data._id}`)
        } catch (err: any) {
            handleError(err, 'active', authDispatch);
        } finally {
            setLoading(false)
        }
    };

    const productDetails = async () => {
        setPageLoading(true)
        try {
            let productRes = (await henceforthApi.Product.getProductDetails(match?.params.id)).data
            setEditProduct({
                ...editProduct,
                name: productRes?.name,
                description: productRes?.description,
                brand_id: productRes?.brand_id?._id,
                category_id: productRes?.category_id?._id,
                subcategory_id: productRes?.subcategory_id?._id,
                sub_subcategory_id: productRes?.sub_subcategory_id?._id,
                tax_percentage: productRes?.tax_percentage,
                images: productRes?.images,
                quantity: productRes?.quantity,
                price: productRes?.price,
                wholesale_quntity: productRes.wholesale_quntity,
                wholesale_price: productRes?.wholesale_price,
                campaign_quantity: productRes?.campaign_quantity,
                campaign_create_type: productRes?.campaign_create_type,
                discount_percantage: productRes?.discount_percantage,
                brandObject: productRes?.brand_id
            })
            setSelected(productRes.campaign_create_selected_members)
        } catch (err: any) {
            handleError(err, '', authDispatch);
        } finally {
            setPageLoading(false)
        }
    }
    useEffect(() => {
        productDetails()
    }, [])
    const imageRemove = (del: string, id: number) => {
        if (del) {
            editProduct.images.splice(id, 1)
            setEditProduct({
                ...editProduct,
            })
        } else {
            selectedFile.splice(id, 1)
            setSelectedFile([
                ...selectedFile,
            ])
        }
    }
    const BrandList = async (search: string) => {
        try {
            const apiRes = await henceforthApi.categorylisting.Brandlisting(search)
            const value = apiRes?.data?.data
            if (apiRes?.data?.data.some((res: any) => res._id !== editProduct.brandObject._id)) {
                value.push(editProduct.brandObject)
            }
            return apiRes?.data?.data;
        } catch (error) {
            return []
        }
    }
    const SelectedUser = async (search: string) => {
        try {
            const apiRes = await henceforthApi.Campaign.getSelectedUser(search)
            if (selected.length) {
                selected.forEach((res: any, index) => {
                    const i = apiRes.data.findIndex((resp: any) => resp._id === res._id)
                    const x = [...selected]
                    if (i > -1) {
                        x[index].index = i
                    } else {
                        const len = apiRes.data.length
                        apiRes.data.push({ ...res })
                        x[index].index = len
                    }
                    setSelected(x)
                })
            }
            setSearchedData(apiRes.data)
            return apiRes?.data;
        } catch (error) {
            return []
        }
    }
    return (
        <Fragment>
            <BreadCrumb pathNameDeclare={breadCrumbPath} />
            {/* page  */}
            {pageLoading ?
                <div className='vh-100 d-flex justify-content-center py-5'>
                    <Spinner color={"text-success"} />
                </div>
                : <div className='page-spacing'>
                    <section className='product-detail'>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12 col-md-12">
                                    {/* Title  */}
                                    <div className="common-card">
                                        <div className="common-card-title">
                                            <h5>{language_data?.[authState.lang]?.common_edit} {language_data?.[authState.lang]?.common_product}</h5>
                                        </div>
                                        {/* Profile  */}
                                        <div className="common-card-content">
                                            <form onSubmit={handleSubmit}>

                                                <div className="upload-fields-box mb-3">
                                                    <label htmlFor="" className='fw-bolder mb-2'>{language_data?.[authState.lang]?.common_product} {language_data?.[authState.lang]?.common_images}</label>
                                                    {/* Upload image */}

                                                    <div className="product-edit-image d-flex gap-2 gap-md-4 gap-lg-5 flex-column flex-sm-row">
                                                        <div className="product-edit-upload">
                                                            <div className='upload-fields-box mb-3'>
                                                                <div className='profile-edit-image mb-2 mx-0 product-edit-upload-image'>
                                                                    <div className='profile-edit-upload'>
                                                                        <input
                                                                            id="images"
                                                                            onChange={(e) => { setSelectedFileErr(''); handleFile(e) }}
                                                                            type="file"
                                                                            className={`form-control ${selectedFileErr ? 'is-invalid' : ''}`}
                                                                            accept='image/png,image/jpeg'
                                                                            multiple
                                                                        />
                                                                    </div>
                                                                    <img src={imageUpload} alt="img" className={` ${selectedFileErr ? 'border border-danger' : ''}`} />
                                                                </div>
                                                                <Errormessage phone={selectedFileErr} error={selectedFileErr} />
                                                            </div>
                                                        </div>
                                                        <div className='product-images-preview d-inline-flex gap-sm-4 gap-md-4 gap-lg-5 gap-3 flex-wrap flex-column flex-sm-row'>
                                                            {editProduct.images && editProduct.images.map((res, index: number) => {
                                                                return (
                                                                    <div key={index} className='preview-image-box position-relative'>
                                                                        <img
                                                                            className="Product-Images border border-light"
                                                                            src={`${henceforthApi.API_FILE_ROOT_MEDIUM}${res}`} alt={res} />
                                                                        {editProduct.images[0] !== "" && <span key={Math.random()} className='preview-image-close ' onClick={() => imageRemove("del", index)}><i className='fa fa-close text-dark' style={{ cursor: 'pointer' }}></i> </span>}
                                                                    </div>
                                                                )
                                                            })}
                                                            {Array.isArray(selectedFile) ? selectedFile.map((res, index: number) => {

                                                                return (
                                                                    <div key={index} className='preview-image-box position-relative'><img
                                                                        className="Product-Images border border-light"
                                                                        src={URL.createObjectURL(res)} alt="img" />
                                                                        {<span key={Math.random()} className='preview-image-close ' onClick={() => imageRemove("", index)}><i className='fa fa-close text-dark' style={{ cursor: 'pointer' }}></i> </span>}
                                                                    </div>
                                                                )
                                                            }) : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                                <p><small><strong>{language_data?.[authState.lang]?.common_note}:-</strong>{language_data?.[authState.lang]?.common_please_upload_only_jpg_and_png_format_only}.</small></p>
                                                <div className='divider my-3'></div>
                                                {/* Product Name  */}
                                                <Inputs.Input type='text' id="productName" error={nameErr} label={`${language_data?.[authState.lang]?.common_product} ${language_data?.[authState.lang]?.common_name}`} name='name' placeholder={`${language_data?.[authState.lang]?.common_product} ${language_data?.[authState.lang]?.common_name}`} value={editProduct.name} handleChange={handleChange} />
                                                {/* Product Description  */}
                                                <Textarea error={descriptionErr} id="description" col={30} row={5} label={`${language_data?.[authState.lang]?.common_product} ${language_data?.[authState.lang]?.common_description}`} name='description' placeholder={`${language_data?.[authState.lang]?.common_product} ${language_data?.[authState.lang]?.common_description}`} value={editProduct.description} handleChange={handleChange} />
                                                {/* Product Price  */}
                                                <Inputs.Input type='text' id="price" error={priceErr} label={`${language_data?.[authState.lang]?.common_product} ${language_data?.[authState.lang]?.common_price}`} name='price' placeholder={`${language_data?.[authState.lang]?.common_price}`} value={editProduct.price} handleChange={handleChange} />
                                                {/* Product tax  */}
                                                <Inputs.Input type='text' error={taxErr} label={`${language_data?.[authState.lang]?.common_tax} ${language_data?.[authState.lang]?.common_percentage} %`} name='tax_percentage' placeholder={`${language_data?.[authState.lang]?.common_tax} ${language_data?.[authState.lang]?.common_percentage}`} value={editProduct.tax_percentage} handleChange={handleChange} />
                                                {/* Quantity */}
                                                <Inputs.Input type='number' id="quantity" error={quantityErr} label={`${language_data?.[authState.lang]?.common_quantity}`} name='quantity' placeholder={`${language_data?.[authState.lang]?.common_quantity}`} value={editProduct.quantity} handleChange={handleChange} />
                                                {/* campaign_create_type */}
                                                <Select Array={CampaignCreateType} id="campaign_create_type" error={campaignCreateTypeErr} label={`${language_data?.[authState.lang]?.common_campaign} ${language_data?.[authState.lang]?.common_create} ${language_data?.[authState.lang]?.common_type}`} firstSelect='Type' name='campaign_create_type' value={ query.get('campaign_type') || editProduct.campaign_create_type } handleChange={handleChange} />
                                                {/* campaign_create_selected_members */}
                                                {editProduct.campaign_create_type === 'SELECTED_USER' ? <div className='mb-3'>
                                                    <label className='fw-bold mb-1'>{language_data?.[authState.lang]?.common_campaign} {language_data?.[authState.lang]?.common_select} {language_data?.[authState.lang]?.common_user}</label>
                                                    <Debounce others={selected?.length ? { mode: 'multiple', value: selected?.map(res => res.index) } : { mode: 'multiple', placeholder: `${language_data?.[authState.lang]?.common_campaign} ${language_data?.[authState.lang]?.common_select} ${language_data?.[authState.lang]?.common_user}` }} error={''} debounceClass=" w-100"
                                                        onChange={(e: any) => {
                                                            if (e?.length > selected?.length) {
                                                                setSelected([...selected, { ...searchData[+e?.at(-1).value], index: +e?.at(-1).value }])
                                                            } else if (e?.length) {
                                                                const x = e?.map((res: any) => { return { ...searchData[+res?.value], index: +res?.value } })
                                                                setSelected(x)
                                                            } else if (!e?.length) {
                                                                setSelected([])
                                                            }

                                                        }} searchCharacters={SelectedUser} options={(results: any) => { return results.length ? results?.map((res: any, index: number) => { return { value: index, label: res.email ? `${res.email}` : 'N/A' } }) : [] }} />
                                                </div> : ''}
                                                {/* wholesale_quntity */}
                                                <Inputs.Input type='text' id="wholesale_quntity" maxLength={6} error={wholesaleQuantityErr} label={`${language_data?.[authState.lang]?.common_total} ${language_data?.[authState.lang]?.common_wholesale} ${language_data?.[authState.lang]?.common_quantity}`} name='wholesale_quntity' placeholder={`${language_data?.[authState.lang]?.common_total} ${language_data?.[authState.lang]?.common_wholesale} ${language_data?.[authState.lang]?.common_quantity}`} value={editProduct.wholesale_quntity} handleChange={handleChange} />
                                                {/* wholesale_price */}
                                                <Inputs.Input type='text' id="wholesale_price" maxLength={6} error={wholesalePriceErr} label={`${language_data?.[authState.lang]?.common_wholesale} ${language_data?.[authState.lang]?.common_price}`} name='wholesale_price' placeholder={`${language_data?.[authState.lang]?.common_wholesale} ${language_data?.[authState.lang]?.common_price}`} value={editProduct.wholesale_price} handleChange={handleChange} />
                                                {/* campaign_quantity */}
                                                <Inputs.Input type='text' id="campaign_quantity" maxLength={6} error={query.get('campaign_type') === "NO_ONE" ? false : campaignQuantityErr}label={`${language_data?.[authState.lang]?.common_campaign} ${language_data?.[authState.lang]?.common_quantity}`} name='campaign_quantity' placeholder={`${language_data?.[authState.lang]?.common_campaign} ${language_data?.[authState.lang]?.common_quantity}`} value={editProduct.campaign_quantity} handleChange={handleChange} disabled={query.get('campaign_type') === "NO_ONE"}/>
                                                {/* Brand  */}
                                                <div className='mb-3'>
                                                    <label className='fw-bold mb-1'>{language_data?.[authState.lang]?.common_brand}</label>
                                                    <Debounce others={editProduct.brand_id ? { value: editProduct.brand_id } : { placeholder: `${language_data?.[authState.lang]?.common_search} ${language_data?.[authState.lang]?.common_brand}` }} error={brandErr} debounceClass=" w-100" onChange={(e: any) => {
                                                        handleChange({ target: { name: 'brand_id', value: e.value } })
                                                    }} searchCharacters={BrandList} options={(results: any) => { return results.length ? results?.map((res: any) => { return { value: res?._id, label: res.name ? res.name?.slice(0, 20) : 'N/A' } }) : [] }} />
                                                </div>
                                                {/* <Select Array={brandDetails.brand} id="brand_id" error={brandErr} label={`${language_data?.[authState.lang]?.common_brand}`} firstSelect='Brand' name='brand_id' value={editProduct.brand_id} handleChange={handleChange} /> */}
                                                {/* Category-1  */}
                                                <Select Array={brandDetails.category} id="category_id" error={categoryErr} label={`${language_data?.[authState.lang]?.common_category_level} ${language_data?.[authState.lang]?._1}`} firstSelect='Category' name='category_id' value={editProduct.category_id} handleChange={handleChange} />
                                                {/* Category-2  */}
                                                <Select Array={subCategory} disabled={!editProduct.category_id} error={subCategoryErr} label={`${language_data?.[authState.lang]?.common_category_level} ${language_data?.[authState.lang]?._2}`} firstSelect='SubCategory' name='subcategory_id' value={editProduct.subcategory_id} handleChange={handleChange} />
                                                {/* Category-3  */}
                                                <Select Array={subSubCategory} disabled={!editProduct.subcategory_id} label={`${language_data?.[authState.lang]?.common_category_level} ${language_data?.[authState.lang]?._3}`} firstSelect='Sub_SubCategory' name='sub_subcategory_id' value={editProduct.sub_subcategory_id} handleChange={handleChange} />
                                                {/* Discount  */}
                                                <Inputs.Input type='number' label={`${language_data?.[authState.lang]?.common_discount}`} name='discount_percantage' placeholder={`${language_data?.[authState.lang]?.common_enter} ${language_data?.[authState.lang]?.common_your} ${language_data?.[authState.lang]?.common_discount}`} value={editProduct.discount_percantage} handleChange={handleChange} />
                                                {/* <Select Array={commonArray?.discount} label='Discount' firstSelect='Discount' name='discount_percantage' value={editProduct.discount_percantage} handleChange={handleChange} /> */}
                                                <div className="divider my-3"></div>
                                                {/* Buttons  */}
                                                <SaveCancelButton loading={loading} color="text-white" onClick={handleSubmit} />
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>}
        </Fragment>
    )
}
export default EditProduct;