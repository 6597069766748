import { useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Swal from 'sweetalert2';
import user_img from '../../assets/images/sidebar/defaultImage.jpg';
import loginSuccess from '../../context/actions/auth/loginSuccess';
import { GlobalContext, handleError } from '../../context/Provider'
import henceforthApi from '../../utils/henceforthApi'
import henceofrthEnums from '../../utils/henceofrthEnums';
import { capitalise } from '../../utils/validations';
import Notification from './Notification';
// import profile_img from '../../assets/images/pages/profile-image.jpg';
import profile_img from '../../assets/images/pages/defaultImage.jpg';


const TheHeader = (props: any) => {
    const { authState, logOutNow, authDispatch, language_data, loading, setLoading } = useContext(GlobalContext)
    const location = useLocation()
    const [hideShow, setHideShow] = useState<boolean>(true)
    const [messages, setMessages] = useState({
        unread_notifications: [],
        read_notifications: [],
        unread_count: 0
    })
    // const { authState, authDispatch } = useContext(GlobalContext);

    const onChangeLanguage = (value: string) => {

        loginSuccess({ lang: value })(authDispatch)
    }

    const toggleHandler = (pass: boolean) => {
        setHideShow(!hideShow)
        props.changeDiv(pass)
    }

    const logOut = async () => {
        Swal.fire({
            title: `${language_data?.[authState.lang]?.common_are_you_sure}`,
            text: `${language_data?.[authState.lang]?.common_are_sure_want_to} ${language_data?.[authState.lang]?.common_logout}`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: `${language_data?.[authState.lang]?.common_cancel}`,
            confirmButtonText: `${language_data?.[authState.lang]?.common_yes}`,
        }).then(async (result: any) => {
            if (result.isConfirmed) {
                logOutNow()
            }
        })
    }
    const getMessagesApi = async () => {
        try {
            let res = (await henceforthApi.Notification.getNotification()).data
            setMessages(res)
        } catch (err) {
            handleError(err, '', authDispatch)
        }
    }

    const markClearNotification = async (seletApi: string) => {
        if (loading) return
        setLoading(true)
        try {
            if (seletApi) await henceforthApi.Notification.markAllAsRead({})
            else await henceforthApi.Notification.clearAllNotification({})
            await getMessagesApi()
        } catch (error) {
            handleError(error, '', authDispatch)
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        // eslint-disable-next-line
        getMessagesApi()
    }, [location.pathname])
    return (
        <div className="container-fluid">
            <div className="row border-bottom">
                <nav className="navbar d-flex justify-content-between align-items-center p-3 bg-white" role="navigation" style={{ marginBottom: "0" }}>
                    {/* left side  */}
                    <div className="navbar-header d-none d-sm-block">
                        <button className="btn btn-theme shadow-none rounded-1" onClick={() => toggleHandler(!hideShow)}>
                            <i className="fa fa-bars"></i>
                        </button>
                        {/* serach  */}
                        {/* <form role="search" className="navbar-form-custom">
                            <div className="form-group">
                                <input type="text" placeholder="Search for something..." className="form-control shadow-none bg-transparent border-0" name="top-search" id="top-search" />
                            </div>
                        </form> */}
                    </div>

                    {/* right side  */}
                    <ul className="nav gap-2 gap-md-3 main-nav align-items-center ms-auto justify-content-md-start">
                        {/* welcome text  */}
                        <li>
                            <p className='fw-semibold d-none d-lg-block'>{language_data?.[authState.lang]?.header_welcome_to_henceForth_ecommerce_seller_panel}</p>
                        </li>
                        <li className="header-profile-dropdown">
                            <div className="dropdown">
                                <button className="btn shadow-none border-0 dropdown-toggle p-0 text-muted" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src={authState?.image?.endsWith('.webp') ? `${henceforthApi.API_FILE_ROOT_ORIGINAL}${authState.image}` : authState.image ? `${henceforthApi.API_FILE_ROOT_MEDIUM}${authState.image}` : profile_img} className="rounded-circle" />

                                    {/* <img src={authState?.image?.endsWith('.webp') ? `${henceforthApi.API_FILE_ROOT_ORIGINAL}${authState.image}` : !authState?.image?.endsWith('.webp') ? `${henceforthApi.API_FILE_ROOT_MEDIUM}${authState.image}` : profile_img} alt={authState.image} className='rounded-circle' /> */}
                                </button>
                                <ul className="dropdown-menu py-0">
                                    <li className='px-2 pt-2'><Link className="dropdown-item" to="/profile">{language_data?.[authState.lang]?.common_profile}</Link></li>
                                    <li className='px-2 pt-2'><Link className="dropdown-item" to="/change-password">{language_data?.[authState.lang]?.common_change_password}</Link></li>
                                    <li className='divider'></li>
                                    <li className='px-2 py-2'><Link className="dropdown-item" to="" onClick={logOut}>{language_data?.[authState.lang]?.common_logout}</Link></li>
                                </ul>
                            </div>
                        </li>

                        {/* Language  */}
                        {/* <li className='px-2 pt-2' onClick={onChangeLanguage}>ENGLISH</li> */}
                        {/* <li className="header-profile-dropdown">
                            <div className="dropdown">
                                <button className="btn shadow-none border-0 dropdown-toggle p-0 text-muted" type="button" data-bs-toggle="dropdown" aria-expanded="false" >
                                    <i className="fa fa-sharp fa-solid fa-globe"></i>
                                </button>
                                <ul className="dropdown-menu py-0" >
                                    <li className='px-2 pt-2' role="button" onClick={() => onChangeLanguage(henceofrthEnums.Language.ENGLISH)}>{capitalise(henceofrthEnums.Language.ENGLISH)}</li>
                                    <li className='divider'></li>
                                    <li className='px-2 py-2' role="button" onClick={() => onChangeLanguage(henceofrthEnums.Language.ARABIC)}>{capitalise(henceofrthEnums.Language.ARABIC)}</li>
                                </ul>
                            </div>
                        </li> */}
                        {/* Notifications  */}
                        <li>
                            <div className="dropdown">
                                <button className="btn btn-secondary dropdown-button position-relative bg-transparent border-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={getMessagesApi}>
                                    <i className="fa fa-bell"></i>  <span className="custom-badge badge rounded-1 text-bg-success text-white">{messages.unread_count == 0 ? '' : messages.unread_count}</span>
                                </button>
                                <ul className="dropdown-menu dropdown-menu-end py-0 border-0 notification-dropdown ">
                                    <li className='px-3 py-2 border-bottom d-flex gap-2 justify-content-between'>
                                        <button className='btn p-0 text-success fw-semibold' onClick={() => { markClearNotification('markDone') }}>{language_data?.[authState.lang]?.common_mark_all_as_read}</button>
                                        <button className='btn p-0 text-danger fw-semibold' onClick={() => { markClearNotification('') }}>{language_data?.[authState.lang]?.common_clear}</button>
                                    </li>
                                    <Notification NotificationHeading={`New Notification`} checkLength={messages.unread_notifications.length} Array={messages.unread_notifications} Notifications={language_data?.[authState.lang]?.common_nothing_new_for_you} getMessagesApi={getMessagesApi} />
                                    <li></li>
                                    <Notification NotificationHeading="Previous notifications" checkLength={messages.read_notifications.length} Array={messages.read_notifications} Notifications="" getMessagesApi={getMessagesApi} readNotification={true} viewAll={messages.unread_notifications.length === 10 || messages.read_notifications.length === 10} />
                                </ul>
                            </div>
                        </li>
                        {/* Logout button  */}
                        <li>
                            <button type="button" className="btn btn-theme shadow-none logout-btn border-0 fw-fw-semibold text-white" onClick={logOut}>
                                <i className="fa fa-sign-out text-white me-1"></i>{language_data?.[authState.lang]?.common_logout}
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>

    )
}

export default TheHeader;